import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { StateService } from '../services/state.service';

export const localeInterceptor: HttpInterceptorFn = (req, next) => {
  const activeLocale = inject(StateService).getActiveLocale();

  return next(
    req.clone({
      params: req.params.set('locale', activeLocale),
    }),
  );
};
