import { TranslocoService } from '@jsverse/transloco';
import { switchMap } from 'rxjs/operators';
import { APP_INITIALIZER } from '@angular/core';
import { map, Observable } from 'rxjs';
import { StateService } from '../services/state.service';
import { SpreeService } from '../services/spree-client/storefront/spree.service';
import { Store, storeSerializer } from '../services/store-serializer';
import { Location } from '@angular/common';
import { LocaleMap } from '../helpers/locale-map';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import * as Sentry from '@sentry/angular';

function getAvailableLangsAndSetupUrls() {
  return (
    client: SpreeService,
    state: StateService,
    transloco: TranslocoService,
    location: Location,
    cookies: SsrCookieService,
  ): (() => Observable<unknown>) => {
    return () => {
      return client.store.show().pipe(
        map((storeDto) => storeSerializer(storeDto)),
        switchMap((store) => {
          const activeLocale = getActiveLocale(store, cookies, location);
          const activeCurrency = LocaleMap[activeLocale].currency;

          state.setActiveLocale(activeLocale);
          state.setActiveCurrency(activeCurrency);
          state.setStore(store);

          transloco.setDefaultLang(store.defaultLocale);
          transloco.setAvailableLangs(store.supportedLocales);
          transloco.setActiveLang(activeLocale);

          return transloco.load(activeLocale);
        }),
      );
    };
  };
}

function getActiveLocale(
  store: Store,
  cookies: SsrCookieService,
  location: Location,
): string {
  const savedLocale = cookies.get('locale') ?? '';
  const urlLocale = getLocaleFromUrl(location.path()) ?? '';

  if (store.supportedLocales.includes(urlLocale)) {
    return urlLocale;
  }

  if (store.supportedLocales.includes(savedLocale)) {
    return savedLocale;
  }

  return store.defaultLocale;
}

function getLocaleFromUrl(url: string): string | null {
  if (!url) {
    return null;
  }

  const parts = url.split('/');

  if (parts[1]) {
    return parts[1];
  } else {
    return null;
  }
}

export const APP_INITIALIZER_PROVIDER = {
  provide: APP_INITIALIZER,
  multi: true,
  deps: [
    SpreeService,
    StateService,
    TranslocoService,
    Location,
    SsrCookieService,
    Sentry.TraceService,
  ],
  useFactory: getAvailableLangsAndSetupUrls(),
};
